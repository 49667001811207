import EmblaCarousel, { EmblaCarouselType } from "embla-carousel";
import useAnalytics from "@composables/useAnalytics";

interface HighlightSlidesOptions {
  active: boolean;
  activeClass: string;
  targetSelector: string;
  maxWidth: number;
}

class DefaultCarousel extends HTMLElement {
  emblaOptions: Record<string, any> | null = null;
  isSegmentTrack: boolean = false;
  emblaApi: EmblaCarouselType | null = null;
  slideLinksElement: NodeListOf<HTMLElement> | null = null;
  nextBtn: HTMLElement | null = null;
  prevBtn: HTMLElement | null = null;
  dotsNode: HTMLElement | null = null;
  dotNodes: HTMLElement[] = [];
  emblaContainer: HTMLElement | null = null;
  highlightSlidesOptions: HighlightSlidesOptions | null = null;

  constructor() {
    super();
    this.setupElements();
    this.mount();
  }

  setupElements() {
    const { containerId, emblaOptions, highlightOptions } =
      this.parseEmblaData();
    if (!containerId || !this.querySelector(`#${containerId}`)) {
      console.error(
        "No container id found. Please add data-embla-container-id attribute to the carousel element."
      );
      return;
    }

    this.emblaOptions = emblaOptions;
    this.nextBtn = this.querySelector(".embla-button--next");
    this.prevBtn = this.querySelector(".embla-button--prev");
    this.dotsNode = this.querySelector(".embla-dots");
    this.emblaContainer = this.querySelector(`#${containerId}`);
    this.isSegmentTrack = this.dataset.emblaEnableSegmentTrack === "true";
    this.slideLinksElement =
      this.querySelectorAll<HTMLElement>(".embla__slide-link");
    this.highlightSlidesOptions = highlightOptions as HighlightSlidesOptions;
  }

  segmentTrack(action: string, name: string): void {
    const { segmentTrack } = useAnalytics();
    segmentTrack("Product List Filtered", {
      filter_action: action,
      filter_name: name,
    });
  }

  parseEmblaData(): {
    containerId: string | null;
    emblaOptions: Record<string, any> | null;
    highlightOptions: Record<string, any> | null;
  } {
    const containerId = this.dataset.emblaContainerId;
    const emblaOptionsString = this.dataset.emblaOptions;
    const highlightOptionsString = this.dataset.emblaHighlightOptions;

    const result = {
      containerId: containerId || null,
      emblaOptions: null,
      highlightOptions: null,
    };

    if (emblaOptionsString) {
      try {
        result.emblaOptions = JSON.parse(emblaOptionsString);
      } catch (error) {
        console.error("Error parsing data-embla-options attribute:", error);
      }
    }

    if (highlightOptionsString) {
      try {
        result.highlightOptions = JSON.parse(highlightOptionsString);
      } catch (error) {
        console.error(
          "Error parsing data-embla-highlight-options attribute:",
          error
        );
      }
    }

    return result;
  }

  mount() {
    this.initializeEmblaApi();

    if (this.highlightSlidesOptions) {
      this.highlightSlidesInViewportHandler();
    }

    if (this.nextBtn && this.prevBtn) {
      const removePrevNextBtnsClickHandlers =
        this.addPrevNextBtnsClickHandlers();
      this.emblaApi?.on("destroy", removePrevNextBtnsClickHandlers);
    }

    if (this.dotsNode) {
      const removeDotBtnsAndClickHandlers = this.addDotBtnsAndClickHandlers();
      this.emblaApi?.on("destroy", removeDotBtnsAndClickHandlers);
    }

    if (this.isSegmentTrack && this.slideLinksElement?.length) {
      this.slideLinksElement.forEach((slideLinkElement) => {
        slideLinkElement.addEventListener("click", () => {
          const { segmentTrack } = this;
          if (segmentTrack) {
            segmentTrack("added", slideLinkElement.textContent?.trim() || "");
          }
        });
      });
    }
  }

  initializeEmblaApi() {
    if (this.emblaContainer) {
      this.emblaApi = this.emblaOptions
        ? EmblaCarousel(this.emblaContainer, this.emblaOptions)
        : EmblaCarousel(this.emblaContainer, {});
    }
  }

  addTogglePrevNextBtnsActive() {
    const togglePrevNextBtnsState = () => {
      if (this.emblaApi?.canScrollPrev()) {
        this.prevBtn?.classList.remove("invisible");
      } else {
        this.prevBtn?.classList.add("invisible");
      }

      if (this.emblaApi?.canScrollNext()) {
        this.nextBtn?.classList.remove("invisible");
      } else {
        this.nextBtn?.classList.add("invisible");
      }
    };

    this.emblaApi?.on("select", togglePrevNextBtnsState);
    this.emblaApi?.on("init", togglePrevNextBtnsState);
    this.emblaApi?.on("reInit", togglePrevNextBtnsState);

    return () => {
      this.prevBtn?.classList.remove("invisible");
      this.nextBtn?.classList.remove("invisible");
    };
  }

  addPrevNextBtnsClickHandlers() {
    const scrollPrev = () => this.emblaApi?.scrollPrev();
    const scrollNext = () => this.emblaApi?.scrollNext();

    this.prevBtn?.addEventListener("click", scrollPrev, false);
    this.nextBtn?.addEventListener("click", scrollNext, false);

    const removeTogglePrevNextBtnsActive = this.addTogglePrevNextBtnsActive();

    return () => {
      removeTogglePrevNextBtnsActive();
      this.prevBtn?.removeEventListener("click", scrollPrev, false);
      this.nextBtn?.removeEventListener("click", scrollNext, false);
    };
  }

  addDotBtnsAndClickHandlers() {
    const addDotBtnsWithClickHandlers = () => {
      this.dotsNode!.innerHTML = this.emblaApi!.scrollSnapList()
        .map(
          () =>
            `<button class="embla-dot w-2 h-2 rounded-full bg-neutral-300 m-0.5" type="button" aria-label="Scroll to">
            </button>`
        )
        .join("");

      this.dotNodes = Array.from(this.dotsNode!.querySelectorAll(".embla-dot"));
      this.dotNodes.forEach((dotNode, index) => {
        dotNode.addEventListener(
          "click",
          () => this.emblaApi?.scrollTo(index),
          false
        );
      });
    };

    const toggleDotBtnsActive = () => {
      const previous = this.emblaApi?.previousScrollSnap();
      const selected = this.emblaApi?.selectedScrollSnap();
      this.dotNodes[previous!].classList.remove("bg-neutral-900");
      this.dotNodes[selected!].classList.add("bg-neutral-900");
    };

    this.emblaApi!!.on("init", addDotBtnsWithClickHandlers)
      .on("reInit", addDotBtnsWithClickHandlers)
      .on("init", toggleDotBtnsActive)
      .on("reInit", toggleDotBtnsActive)
      .on("select", toggleDotBtnsActive);

    return () => {
      this.dotsNode!.innerHTML = "";
    };
  }

  highlightSlidesInViewportHandler() {
    const { highlightSlidesOptions } = this;

    if (!highlightSlidesOptions?.active) return;

    const { activeClass, targetSelector, maxWidth } = highlightSlidesOptions;

    if (!activeClass || !targetSelector || !maxWidth) {
      console.warn(
        "Please provide activeClass, targetSelector, and maxWidth in highlightSlidesOptions"
      );
      return;
    }

    const highlightHandler = () => {
      const slides = this.emblaApi?.slideNodes();
      const slidesInView = this.emblaApi?.slidesInView();

      if (!slides || !slidesInView) return;

      slides.forEach((slide, index) => {
        const isSlideInView = slidesInView.includes(index);
        const targetNode = slide.querySelector(targetSelector);

        if (targetNode) {
          targetNode.classList.toggle(activeClass, isSlideInView);
        }
      });
    };

    const removeHighlightHandler = () => {
      this.emblaApi?.off("slidesInView", highlightHandler);
      const slides = this.emblaApi?.slideNodes();

      slides?.forEach((slide) => {
        const targetNode = slide.querySelector(targetSelector);
        if (targetNode) {
          targetNode.classList.remove(activeClass);
        }
      });
    };

    const addHighlightHandler = () =>
      this.emblaApi?.on("slidesInView", highlightHandler);

    const targetSize = () => window.innerWidth <= maxWidth;

    if (targetSize()) {
      addHighlightHandler();
    }

    this.emblaApi?.on("resize", () => {
      if (targetSize()) {
        addHighlightHandler();
      } else {
        removeHighlightHandler();
      }
    });
  }
}

if (!customElements.get("carousel-component")) {
  customElements.define("carousel-component", DefaultCarousel);
}
